* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  overflow: hidden;
  background-color: #F2F2F4;
}

#root {
  height: 100%;
  width: 100vw;
  position: relative;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.Toastify__toast {
  min-height: 40px;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

/* Professional background and button style */
.launch-button {
  background-color: #007bff; /* Professional Blue for a clean look */
  color: white;
  transition: all 0.3s ease;
}

/* Animation on hover */
.launch-button:hover {
  background-color: #0056b3; /* Darker blue */
  transform: scale(1.25); /* Slight enlarge on hover */
}

/* Shine animation */
@keyframes shine {
  0% {
    top: -100%;
    left: -100%;
  }
  50% {
    top: 100%;
    left: 100%;
  }
  100% {
    top: -100%;
    left: -100%;
  }
}

/* Professional launch icon animation with blue variants */
.launch-icon {
  color: #007bff; /* Default blue color */
  transition: all 0.5s ease-in-out; /* Smooth transition */
  animation: icon-color-cycling 4s infinite ease-in-out; /* Infinite cycling effect */
}

/* Color cycling effect for the icon using blue variants */
@keyframes icon-color-cycling {
  0% {
    color: #007bff; /* Light Blue */
  }
  25% {
    color: #0069d9; /* Slightly darker blue */
  }
  50% {
    color: #0056b3; /* Dark Blue */
  }
  75% {
    color: #0069d9; /* Slightly darker blue */
  }
  100% {
    color: #007bff; /* Back to light blue */
  }
}
/* Apply animation to the icon */
.launch-icon:hover {
  transform: scale(1.25);
}